import { useSelector } from "react-redux";
import React, { useState, useRef, useEffect, Fragment } from "react";
import dotenv from "dotenv";
import { Button, Modal, Form } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import rrulePlugin from "@fullcalendar/rrule";
import { RRule } from "rrule";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import es from "@fullcalendar/core/locales/es";
import CounterInput from "react-counter-input";
import Select from "react-select";
import "./calendar.css";
import { formatDate, formatRawDate } from "../../helpers/dateHelper";
// apis
import { getAllGroupList } from "../../services/group.service";
import { getAllHikersUsers } from "../../services/hikerUser.service";
import {
  postNewMessage,
  getCalendarEvents,
  deleteEvent,
  editMessage,
} from "../../services/calendar.service";
import { formatDateToHTMLDateTimeLocal } from "../../common/utils";

export const Calendar = () => {
  dotenv.config();

  const server_app = process.env.REACT_APP_AXIOS_LOGIN;

  const [alert, setAlert] = useState({
    type: "danger",
    title: null,
    message: null,
    display: false,
  });
  const sys_user = useSelector((state) => state.user.user);
  const sys_user_id = sys_user.sys_user_id;

  // refresh key
  const [refreshKey, setRefreshKey] = useState(0);

  // modal states
  const [show, setShow] = useState(false);
  const [showEvent, setShowEvent] = useState(false);

  //mensajes programados, se deben traer desde backend
  const [events, setEvents] = useState([]);

  // Detalle del evento clickeado
  const [eventID, setEventId] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [eventAuthor, setEventAuthor] = useState("");
  const [eventMessage, setEventMessage] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventEnd, setEventEnd] = useState("");
  const [usersAgendados, setUsersAgendados] = useState([]);
  const [eventActive, setEventActive] = useState(0);
  const [eventMedia, setEventMedia] = useState("");
  const [isClone, setIsClone] = useState(false);
  const [eventRepeatType, setEventRepeatType] = useState("");
  const [eventRepeatCicle, setEventRepeatCicle] = useState(0);
  const [eventRepeatMonthDay, setEventRepeatMonthDay] = useState(null);

  //form states handler:
  const dateToday = new Date().toISOString().split("T")[0] + "T00:00:00";
  const [minDateTime] = useState(dateToday);
  // repeat period
  const [repeatPeriodOpts, setRepeatPeriodOpts] = useState([
    { value: 0, label: "Año" },
    { value: 1, label: "Mes" },
    { value: 2, label: "Semana" },
    { value: 3, label: "Día" },
  ]);

  const [finOptRadio, setFinOptRadio] = useState(0);
  const [finDatetimeOpt, setDisabledDateTimeOpt] = useState(true);
  const [btnFinCounterStyle, setBtnFinCounterStyle] = useState({
    pointerEvents: "none",
    opacity: "0.6",
  });
  const [inputFinCounterStyle, setInputFinCounterStyle] = useState({
    opacity: "0.5",
    pointerEvents: "none",
  });
  const [showRepeatWeek, showDayWeeks] = useState(false);
  const [showRepeatMonth, showDaysCounter] = useState(false);
  const [isGlobalSend, showGlobalSend] = useState(false);
  const [fillDestinatarios, setFillDestinatarios] = useState(null);
  const [repetitionEndDate, setRepetitionEndDate] = useState(null);
  const [type, setType] = useState(null); // global, group, user
  const destinatariosSelectRef = useRef();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [groupOptions, setGroupOptions] = useState([]);
  const [usersOptions, setUsersOptions] = useState([]);

  // ****** START FORM FIELDS ***************
  const [message_name, setMessageName] = useState("");
  const [message_send_date, setSendDate] = useState("");
  const [repeat_message, setRepeatMsg] = useState(0);
  // depends if repeat_message = 1 :
  const [repeat_periodCount, setRepeatPeriodCount] = useState(1);
  const [repeat_periodDate, setRepeatPeriodDate] = useState({
    value: 0,
    label: "Año",
  });
  const [repeat_week_days, setRepeatWeekDays] = useState([
    { key: 0, id: "weekday-mon", day: "monday", label: "L", checked: false },
    { key: 1, id: "weekday-tue", day: "tuesday", label: "M", checked: false },
    { key: 2, id: "weekday-wed", day: "wednesday", label: "M", checked: false },
    { key: 3, id: "weekday-thu", day: "thursday", label: "J", checked: false },
    { key: 4, id: "weekday-fri", day: "friday", label: "V", checked: false },
    { key: 5, id: "weekday-sat", day: "saturday", label: "S", checked: false },
    { key: 6, id: "weekday-sun", day: "sunday", label: "D", checked: false },
  ]);
  const [repeat_month_day, setRepeatMonthDay] = useState(null);
  const [repeat_end_otp, setRepeatEndOpt] = useState(0);
  const [repeat_endDate, setRepeatEndDate] = useState(null);
  const [repeat_periodEnd, setRepeatPeriodEnd] = useState(5);
  // ********************************

  const [sendType, setSendType] = useState(0);
  const [destinatarios, setDestinatarios] = useState(null);
  const [message, setMessage] = useState("");
  const [media_file, setMediaFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  // ****** END FORM FIELDS ***************

  // RENDER week Days
  const weekDays = repeat_week_days.map((day, index) => (
    <>
      <input
        id={day.id}
        key={day.key}
        type="checkbox"
        className="weekday"
        checked={day.checked}
        onChange={(event) => onChangeDayRepeat(event, index, day.day)}
      />
      <label htmlFor={day.id}>{day.label}</label>
    </>
  ));

  const setField = (field, value) => {
    if (field === "name") setMessageName(value);

    if (field === "send_date") setSendDate(value);

    if (field === "end_date") setRepeatEndDate(value);

    if (field === "message") setMessage(value);

    if (field === "destiny") setDestinatarios(value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const deleteMessage = () => {
    deleteEvent(eventID)
      .then((response) => {
        setAlert({
          title: "Exito!",
          type: "success",
          message: response.data.message,
          display: true,
        });
        setRefreshKey((oldKey) => oldKey + 1);
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        alert("Error al eliminar mensaje");
        console.log(err);
      });
  };

  const findFormErrors = () => {
    const { name, send_date, end_date, message } = form;
    const newErrors = {};

    // name errors
    if (!name || name === "") newErrors.name = "Ingrese título de mensaje";
    //else if ( name.length > 30 ) newErrors.name = 'Título demasiado largo'
    // date errors
    if (!send_date || send_date === "")
      newErrors.send_date = "Ingrese fecha de envío";

    if (repeat_end_otp == 1 && (!end_date || end_date === ""))
      newErrors.end_date = "Ingrese fecha de finalización";
    // message errors
    if (!message || message === "")
      newErrors.message = "Ingrese un mensaje a enviar";

    return newErrors;
  };

  const HandleRepeatMsg = (event) => {
    setRepeatMsg(event);
    console.log(event);
  };

  /* Start Modal new event handle */
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const openModal = () => {
    // Limpiar todos los campos del formulario
    setMessageName("");
    setSendDate("");
    setRepeatMsg(0);
    setRepeatPeriodCount(1);
    setRepeatPeriodDate({ value: 0, label: "Año" });
    setRepeatWeekDays([
      { key: 0, id: "weekday-mon", day: "monday", label: "L", checked: false },
      { key: 1, id: "weekday-tue", day: "tuesday", label: "M", checked: false },
      { key: 2, id: "weekday-wed", day: "wednesday", label: "M", checked: false },
      { key: 3, id: "weekday-thu", day: "thursday", label: "J", checked: false },
      { key: 4, id: "weekday-fri", day: "friday", label: "V", checked: false },
      { key: 5, id: "weekday-sat", day: "saturday", label: "S", checked: false },
      { key: 6, id: "weekday-sun", day: "sunday", label: "D", checked: false },
    ]);
    setRepeatMonthDay(null);
    setRepeatEndOpt(0);
    setRepeatEndDate(null);
    setRepeatPeriodEnd(5);
    
    // Establecer tipo de envío por defecto en "Usuarios" (valor 2)
    setSendType(2);
    
    // Configurar el tipo como "user"
    setType("user");
    
    // Mostrar el selector de destinatarios
    showGlobalSend(true);
    
    // Preparar las opciones de destinatarios (usuarios)
    var filteredHikers = usersOptions.filter((hk) => {
      return hk.hiker_user_whatsapp_active == "1";
    });
    
    let options = [];
    filteredHikers.forEach((e) => {
      if (e["group.group_name"] != null) {
        options.push({
          value: e.hiker_user_id,
          label:
            e.hiker_user_name +
            " " +
            e.hiker_user_lastname +
            " (" +
            e["group.group_name"] +
            ")",
        });
      } else {
        options.push({
          value: e.hiker_user_id,
          label:
            e.hiker_user_name + " " + e.hiker_user_lastname + " (No Grupo)",
        });
      }
    });
    setFillDestinatarios(options);
    
    // Limpiar destinatarios seleccionados
    setDestinatarios(null);
    setMessage("");
    setMediaFile(null);
    setFileUrl(null);
    setForm({});
    setErrors({});
    setIsClone(false);
    
    // Limpiar el selector de destinatarios
    if (destinatariosSelectRef.current) {
      destinatariosSelectRef.current.clearValue();
    }
    
    // Mostrar el modal
    handleShow();
  };
  /* End start modal Handle */

  /* Start Modal show event handle */
  const handleModalEvent = (toggle) => {
    setShowEvent(toggle);
  };

  const handleModalEditEvent = () => {
    const eventToEdit = events.find((e) => e.id === parseInt(eventID));
    console.log("eventToEdit", eventToEdit);
    setField("name", eventToEdit.title);
    // console.log('localDateString', localDateString);
    setField("send_date", eventDate.slice(0, -1));
    // setField('send_date', new Date(eventDate))
    console.log("eventDate", eventDate);
    setField("end_date", new Date(eventEnd));
    setField("message", eventMessage.props.children.props.children);
    // setRepetitionEndDate(eventToEdit.message_programmed_end)
    setForm({
      name: eventToEdit.title,
      send_date: eventDate.slice(0, -1),
      end_date: new Date(eventToEdit.message_programmed_end),
      message: eventMessage.props.children.props.children,
    });

    setType(
      eventToEdit.message_type_id === 0
        ? "global"
        : eventToEdit.message_type_id === 1
        ? "group"
        : "user"
    );
    const imageUrl = eventToEdit.media_path
    setFileUrl(imageUrl)
    setRepeatMsg(eventToEdit.repeat_cicle ? 1 : 0);
    HandleOptFinRadio(eventToEdit.repeat_cicle ? 1 : 0); // 0 = never 1 = specific day
    // setFinOptRadio(0)
    if (eventToEdit.rrule) {
      if (eventToEdit.rrule.until) {
        // mark circular checkbox
        // set value input date time
        setRepetitionEndDate(
          formatDateToHTMLDateTimeLocal(
            new Date(eventToEdit.message_programmed_end)
          )
        );
      }
      setRepeatPeriodCount(eventToEdit.rrule.interval);
      changeRepeatSelect(eventToEdit.rrule.interval);
    }
    showGlobalSend(
      eventToEdit.message_type_id === 0 || eventToEdit.message_type_id === 2
    ); // global or user
    HandleRepeatMsg(eventToEdit.repeat_cicle ? 1 : 0);
    selectDestinatarios(eventToEdit.message_type_id);
    if (eventToEdit.message_type_id === 1) {
      let options = [];
      eventToEdit.receivers_list.forEach((e) => {
        options.push({ value: e.receiver_group_id, label: e.group_name });
      });
      setDestinatarios(options);
    }
    if (eventToEdit.message_type_id === 2) {
      var filteredHikers = eventToEdit.receivers_list;
      let options = [];
      filteredHikers.forEach((e) => {
        if (e["user_group_name"] != null) {
          options.push({
            value: e.receiver_hiker_user_id,
            label:
              e.hiker_user_name +
              " " +
              e.hiker_user_lastname +
              " (" +
              e["user_group_name"] +
              ")",
          });
        } else {
          options.push({
            value: e.receiver_hiker_user_id,
            label:
              e.hiker_user_name + " " + e.hiker_user_lastname + " (No Grupo)",
          });
        }
      });
      setDestinatarios(options);
    }
    handleShow();
  };

  const handleModalCloneEvent = () => {
    handleModalEditEvent();
    setIsClone(true);
    handleShow();
  };

  // lanzado cuando se clickea en un día del calendario
  const handleDateClick = (arg) => {
    //alert(arg.dateStr)
    //setEventDate(arg.dateStr);
    //handleShow();
  };

  // object: args.event
  // when calendar item is clicked
  const handleEventClick = (arg) => {
    console.log("Evento completo:", arg.event);
    console.log("Propiedades del evento:", arg.event._def);
    
    // Establecer la fecha para mostrar
    setEventDate(arg.event._def.extendedProps.message_send_date || arg.event.start.toISOString());
    
    setEventId(arg.event._def.publicId);
    setEventTitle(arg.event._def.title);
    setEventAuthor(
      arg.event._def.extendedProps.sys_user_name +
      " " +
      arg.event._def.extendedProps.sys_user_lastname
    );
    setEventMessage(arg.event._def.extendedProps.message);
    setEventEnd(arg.event._def.extendedProps.end_date);
    setEventActive(arg.event._def.extendedProps.active);
    
    // Verificar si hay una ruta de imagen válida
    const mediaPath = arg.event._def.extendedProps.media_path;
    setEventMedia(mediaPath && mediaPath !== "null" ? mediaPath : null);
    
    setEventRepeatType(arg.event._def.extendedProps.repeat_type);
    setEventRepeatCicle(arg.event._def.extendedProps.repeat_cicle);
    
    // Capturar correctamente los destinatarios
    const receivers = arg.event._def.extendedProps.receivers_list || [];
    setUsersAgendados(receivers);
    
    setEventRepeatMonthDay(arg.event._def.extendedProps.repeat_month_day);

    handleModalEvent(true);
  };

  const changeRepeatSelect = (count) => {
    setRepeatPeriodCount(count);

    console.log("cantidad");
    console.log(count);

    if (count >= 2) {
      setRepeatPeriodOpts([
        { value: "year", label: "Años" },
        { value: "month", label: "Meses" },
        { value: "week", label: "Semanas" },
        { value: "day", label: "Días" },
      ]);

      if (repeat_periodDate.value == 0) {
        setRepeatPeriodDate({ value: 0, label: "Años" });
      } else if (repeat_periodDate.value == 1) {
        setRepeatPeriodDate({ value: 1, label: "Meses" });
      } else if (repeat_periodDate.value == 2) {
        setRepeatPeriodDate({ value: 2, label: "Semanas" });
      } else {
        setRepeatPeriodDate({ value: 3, label: "Días" });
      }
    } else {
      setRepeatPeriodOpts([
        { value: 0, label: "Año" },
        { value: 1, label: "Mes" },
        { value: 2, label: "Semana" },
        { value: 3, label: "Día" },
      ]);

      if (repeat_periodDate.value == 0) {
        setRepeatPeriodDate({ value: 0, label: "Año" });
      } else if (repeat_periodDate.value == 1) {
        setRepeatPeriodDate({ value: 1, label: "Mes" });
      } else if (repeat_periodDate.value == 2) {
        setRepeatPeriodDate({ value: 2, label: "Semana" });
      } else {
        setRepeatPeriodDate({ value: 3, label: "Día" });
      }
    }
  };

  // Opción de selección de repetición: año, mes, semana ó día
  const setSelectedOption = (event) => {
    console.log("periodo de repetición");
    console.log(event);
    setRepeatPeriodDate(event);

    if (event.value == 2) {
      showDayWeeks(true);
      showDaysCounter(false);
    } else if (event.value == 1) {
      showDayWeeks(false);
      showDaysCounter(true);
    } else {
      showDayWeeks(false);
      showDaysCounter(false);
    }
  };

  const onChangeDayRepeat = (event, index, day) => {
    setRepeatWeekDays(
      repeat_week_days.map((weekDay) => {
        if (weekDay.day === day) {
          return { ...weekDay, checked: event.target.checked };
        } else {
          return { ...weekDay };
        }
      })
    );
  };

  /* Manejo de selección radio button finalización evento*/
  const HandleOptFinRadio = (event) => {
    setRepeatEndOpt(event);
    //Nunca
    if (event == 1) {
      setDisabledDateTimeOpt(false);
      setBtnFinCounterStyle({ pointerEvents: "none", opacity: "0.6" });
      setInputFinCounterStyle({ opacity: "0.5", pointerEvents: "none" });
    }
    // Día especifico
    else if (event == 2) {
      setDisabledDateTimeOpt(true);
      setBtnFinCounterStyle({});
      setInputFinCounterStyle({});
    }

    //Despues de n° repeticiones
    else {
      setDisabledDateTimeOpt(true);
      setBtnFinCounterStyle({ pointerEvents: "none", opacity: "0.6" });
      setInputFinCounterStyle({ opacity: "0.5", pointerEvents: "none" });
    }

    setFinOptRadio(event);
  };

  // Rellena select destinatarios (grupos o usuarios)
  const selectDestinatarios = (event) => {
    console.log(event);
    onClearSelectDests();
    setSendType(event);

    //2 = users
    if (event == 2) {
      var filteredHikers = usersOptions.filter((hk) => {
        return hk.hiker_user_whatsapp_active == "1";
      });
      setType("user");

      let options = [];
      filteredHikers.forEach((e) => {
        if (e["group.group_name"] != null) {
          options.push({
            value: e.hiker_user_id,
            label:
              e.hiker_user_name +
              " " +
              e.hiker_user_lastname +
              " (" +
              e["group.group_name"] +
              ")",
          });
        } else {
          options.push({
            value: e.hiker_user_id,
            label:
              e.hiker_user_name + " " + e.hiker_user_lastname + " (No Grupo)",
          });
        }
      });
      setFillDestinatarios(options);

      showGlobalSend(true);

      // 1 = groups
    } else if (event == 1) {
      setType("group");
      let options = [];
      groupOptions.forEach((e) => {
        options.push({ value: e.group_id, label: e.group_name });
      });
      setFillDestinatarios(options);

      showGlobalSend(true);
    } else {
      // 0 = global
      setType("global");
      showGlobalSend(false);
      setFillDestinatarios([]);
    }
  };

  const onClearSelectDests = () => {
    if (destinatariosSelectRef.current != undefined) {
      destinatariosSelectRef.current.clearValue();
    }
  };

  const handleDestinatarios = (selected) => {
    console.log("selected");
    console.log(selected);
    setDestinatarios(selected);
  };

  const handleFile = (event) => {
    console.log("event");
    console.log(event);
    const file = event.target.files[0];
    console.log("Selected file:", file);
    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    if (fileMb > 16) {
      setAlert({
        title: "Error!",
        type: "danger",
        message: "El tamaño máximo es de 16MB",
        display: true,
      });
    } else {
      setMediaFile(event.target.files[0]);
    }
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("sys_user_id", sys_user_id);
      formData.append("message_title", message_name);
      
      // Corregir el manejo de la fecha de envío
      // Asegurarse de que la fecha se envía sin ajuste de zona horaria
      let sendDateValue = message_send_date;
      
      // Si estamos editando (no clonando) y la fecha tiene formato ISO con T
      if (!isClone && eventID && typeof sendDateValue === 'string' && sendDateValue.includes('T')) {
        // Crear un objeto Date a partir del string
        const date = new Date(sendDateValue);
        
        // Formatear la fecha manteniendo la hora local exacta
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        
        // Crear un string de fecha en formato ISO sin la Z al final
        sendDateValue = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      }
      
      formData.append("send_date", sendDateValue);
      
      formData.append("repeat_message", repeat_message);
      formData.append("repeat_period", repeat_periodCount);
      formData.append("repeat_period_date", JSON.stringify(repeat_periodDate));
      formData.append("repeat_week_days", JSON.stringify(repeat_week_days));
      formData.append("repeat_month_day", repeat_month_day);
      formData.append("repeat_end_option", repeat_end_otp);
      formData.append("repeat_end_date", repeat_endDate);
      formData.append("send_type", sendType);
      formData.append("destinatarios", JSON.stringify(destinatarios));
      formData.append("message", message);
      formData.append("media_file", media_file);
      formData.append("file_url", fileUrl);

      let response;
      if (isClone || !eventID) {
        response = await postNewMessage(formData);
      } else {
        response = await editMessage(formData, eventID);
      }
      
      if (response.status === 200) {
        setAlert({
          title: "Exito!",
          type: "success",
          message: "Mensaje programado exitosamente",
          display: true,
        });
        setRefreshKey((oldKey) => oldKey + 1);
        handleClose();
        setIsClone(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.log("response error:");
      console.log(error);
      setAlert({
        title: "Error!",
        type: "danger",
        message: "Error al programar mensaje",
        display: true,
      });
    }
  };

  // Dibujo de contenido modal al pulsar evento
  const bluePrintEvent = (event) => {
    console.log("Evento original de la base de datos:", event);
    
    let calendar_event = {};
    calendar_event.id = event.message_id;
    calendar_event.title = event.message_name;
    
    // Asegurarse de que la fecha se pasa correctamente
    calendar_event.start = event.message_send_date;
    
    // Guardar la fecha original en extendedProps
    calendar_event.extendedProps = {
      ...event,
      original_date: event.message_send_date
    };
    
    calendar_event.author = event.sys_user_name + " " + event.sys_user_lastname;
    calendar_event.date = event.message_send_date;
    calendar_event.start_date = event.message_send_date;
    calendar_event.end_date = event.message_programmed_end;
    calendar_event.active = event.message_active;
    calendar_event.media_path = event.message_img_url;
    calendar_event.repeat_type = event.repeat_type_name;
    calendar_event.repeat_cicle = event.repeat_cicle;
    calendar_event.repeat_month_day = event.repeat_month_day;
    calendar_event.message_type_id = event.message_type_id;
    calendar_event.receivers_list = event.receivers_list;
    calendar_event.message_programmed_end = event.message_programmed_end;
    calendar_event.rrule = event.rrule;
    calendar_event.message = (
      <Fragment>
        <p>{event.message_text}</p>
      </Fragment>
    );
    calendar_event.usersAgendados = (
      <Fragment>
        {event.message_type_id == 1 ? (
          <ul>
            {event.receivers_list.map((receiver) => (
              <li>{receiver.group_name}</li>
            ))}
          </ul>
        ) : event.message_type_id == 2 ? (
          <ul>
            {event.receivers_list.map((receiver) => (
              <li>
                {receiver.hiker_user_name +
                  " " +
                  receiver.hiker_user_lastname}
              </li>
            ))}
          </ul>
        ) : (
          <p>Todos los usuarios</p>
        )}
      </Fragment>
    );

    if (event.message_programmed_id != null) {
      calendar_event.startRecur = event.message_send_date;
      calendar_event.endRecur = event.message_programmed_end;
    }

    // mensual
    if (event.repeat_type_id === 1) {
      let day_in_repeat = event.message_send_date;
      if (event.repeat_month_day != 0) {
        day_in_repeat = event.repeat_month_day;
      }

      calendar_event = {
        ...calendar_event,
        rrule: {
          dtstart: event.message_send_date,
          freq: "monthly",
          interval: event.repeat_cicle,
          bymonthday: day_in_repeat,
          until: event.message_programmed_end,
        },
      };
    } else if (event.repeat_type_id === 2) {
      calendar_event = {
        ...calendar_event,
        rrule: {
          dtstart: event.message_send_date,
          freq: "weekly",
          interval: event.repeat_cicle,
          wkst: RRule.MO,
          byweekday: event.days_week,
          until: event.message_programmed_end,
        },
      };

      if (event.days_week.length != 0) {
        calendar_event.daysOfWeek = event.days_week;
      }
    } else if (event.repeat_type_id === 3) {
      calendar_event = {
        ...calendar_event,
        rrule: {
          dtstart: event.message_send_date,
          freq: "daily",
          interval: event.repeat_cicle,
          until: event.message_programmed_end,
        },
      };
    } else if (event.repeat_type_id === 0) {
      calendar_event = {
        ...calendar_event,
        rrule: {
          dtstart: event.message_send_date,
          freq: "yearly",
          interval: event.repeat_cicle,
          until: event.message_programmed_end,
        },
      };
    }

    console.log("Evento formateado para el calendario:", calendar_event);
    return calendar_event;
  };

  // get all events for sys_user_id
  useEffect(() => {
    getAllHikersUsers().then((resp) => {
      //let filters = [{hiker_user_whatsapp_active: 1}];

      setUsersOptions(resp.data);
    });
    getAllGroupList().then((resp) => {
      setGroupOptions(resp.data);
    });

    getCalendarEvents(sys_user_id)
      .then((response) => {
        console.log("Eventos obtenidos del backend:", response.data);
        
        // Guardar las fechas originales para cada evento
        const originalDates = {};
        response.data.forEach(event => {
          originalDates[event.message_id] = event.message_send_date;
        });
        
        // Guardar las fechas originales en el estado
        setOriginalEventDates(originalDates);
        
        let data = response.data;
        console.log("Data events");
        console.log(data);

        setEvents([]);

        data.forEach((event) => {
          console.log(event);

          setEvents((events) => [...events, bluePrintEvent(event)]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refreshKey]);

  // Añadir un nuevo estado para guardar las fechas originales
  const [originalEventDates, setOriginalEventDates] = useState({});

  return (
    <>
      {alert.display ? (
        <SweetAlert
          type={alert.type}
          title={alert.title}
          onConfirm={(param) =>
            setAlert({
              type: "danger",
              title: null,
              message: null,
              display: false,
            })
          }
          // onCancel={this.onCancel}
          timeout={2000}
        >
          {alert.message}
        </SweetAlert>
      ) : null}
      <div className="container-fluid">
        <h1 className="h3 mb-4 text-gray-800">Calendario de Mensajes</h1>
        <button className="btn btn-primary" onClick={openModal}>
          Crear Nuevo Mensaje
        </button>
        <div className="clearfix" style={{ margin: "5px" }}></div>
        {console.log("eventos to show")}
        {events != []
          ? console.log(events)
          : console.log("no eventos cargados")}

        {events != [] ? (
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            events={events}
            eventClick={handleEventClick}
            dateClick={handleDateClick}
            locale={es}
            timeZone="America/Santiago"
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            eventDidMount={(info) => {
              // Personalizar la visualización de cada evento en el calendario
              const eventEl = info.el;
              const timeEl = eventEl.querySelector('.fc-event-time');
              
              if (timeEl && info.event.start) {
                // Obtener la fecha original y formatearla
                const originalDate = info.event._def.extendedProps.message_send_date;
                if (originalDate) {
                  // Usar nuestra función formatRawDate para mostrar la hora correcta
                  const formattedTime = formatRawDate(originalDate).split(' ')[1].substring(0, 5); // Extraer solo HH:MM
                  timeEl.innerHTML = formattedTime;
                }
              }
            }}
          />
        ) : (
          <p>Cargando datos..</p>
        )}
      </div>
      {/* Modal: Preview show event */}
      <Modal
        show={showEvent}
        onHide={(param) => handleModalEvent(false)}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Evento: {eventTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="hidden" name="message_id" value="{}" />
          <p>
            <b>Autor: </b>
            {eventAuthor}
          </p>
          <p>
            <b>Inicio Evento: </b>
            {eventDate ? formatRawDate(eventDate) : 'Sin fecha'}
          </p>
          <p>
            <b>Fin Evento: </b>
            {eventEnd === null ? "Sin finalización" : formatDate(eventEnd)}
          </p>
          <p>
            <b>Repetición: </b>
            {eventRepeatType === null ? "1 vez" : eventRepeatType}
          </p>
          {eventRepeatCicle != null ? (
            <p>
              <b>Repetir cada: </b>
              {eventRepeatCicle}
              {eventRepeatType === "Mensual"
                ? eventRepeatCicle === 1
                  ? " Mes"
                  : " Meses"
                : eventRepeatType === "Semanal"
                ? " Semana"
                : eventRepeatType === "Anual"
                ? " Año"
                : eventRepeatType === "Diario"
                ? eventRepeatCicle === 1
                  ? " Día"
                  : " Dias"
                : null}
            </p>
          ) : null}
          <p>
            {eventRepeatMonthDay != null ? (
              <p>
                {" "}
                <b>Repetir el día: </b> {eventRepeatMonthDay} de cada mes
              </p>
            ) : null}
          </p>
          {/* Sección de imagen */}
          {eventMedia ? (
            <div>
              <img src={eventMedia} width="80%" alt="Imagen del mensaje" />
              <br />
            </div>
          ) : (
            <p>
              <b>Multimedia: </b>Sin imagen
            </p>
          )}
          <p>
            <b>Mensaje: </b>
          </p>
          {eventMessage}
          <br />
          {/* Sección de destinatarios */}
          <div className="mb-3">
            <p>
              <b>Destinatarios:</b>
            </p>
            {usersAgendados && usersAgendados.length > 0 ? (
              <ul>
                {usersAgendados.map((user, index) => (
                  <li key={index}>
                    {user.hiker_user_name} {user.hiker_user_lastname}
                    {user.user_group_name ? ` (${user.user_group_name})` : ' (No Grupo)'}
                  </li>
                ))}
              </ul>
            ) : (
              <p>Sin destinatarios</p>
            )}
          </div>
          <p>
            <b>Evento activo: </b>
            {eventActive === 1 ? "Si" : "No"}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={(e) => handleModalCloneEvent()}>
            Clonar
          </Button>
          <Button variant="primary" onClick={(e) => handleModalEditEvent()}>
            Editar
          </Button>
          <Button variant="secondary" onClick={(e) => handleModalEvent(false)}>
            Cerrar
          </Button>
          <Button variant="danger" onClick={(e) => deleteMessage()}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal: new event */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Form onSubmit={submitForm}>
          <Modal.Header closeButton>
            <Modal.Title>Nuevo mensaje programado</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Los campos con * son obligatorios</p>
            <div className="row">
              <div className="col-5">
                <Form.Label>*Nombre mensaje:</Form.Label>
                <Form.Control
                  className="form-control"
                  id="eventNameInput"
                  type="text"
                  placeholder="Ingrese nombre mensaje"
                  value={message_name}
                  onChange={(e) => setField("name", e.target.value)}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </div>
              <div className="col-4">
                <Form.Label>*Fecha y hora de envio inicial: </Form.Label>
                <Form.Control
                  className="form-control"
                  id="eventDateStart"
                  type="datetime-local"
                  value={message_send_date}
                  onChange={(e) => setField("send_date", e.target.value)}
                  min={minDateTime}
                  isInvalid={!!errors.send_date}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.send_date}
                </Form.Control.Feedback>
              </div>
            </div>
            {/* Row closed */}
            <div className="clearfix" style={{ margin: "15px" }}></div>
            {/* Event repeat radio */}
            <div className="row">
              {/* <div className="col">
                <label htmlFor="">Repetir mensaje: </label>
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="repeatRd1"
                      value="1"
                      onChange={(e) => HandleRepeatMsg(1)}
                      checked={repeat_message === 1 ? true : false}
                    />
                    <label className="form-check-label" htmlFor="repeatRd1">
                      Si
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="repeatRd2"
                      value="0"
                      onChange={(e) => HandleRepeatMsg(0)}
                      checked={repeat_message === 0 ? true : false}
                    />
                    <label className="form-check-label" htmlFor="repeatRd2">
                      No
                    </label>
                  </div>
                </div>
              </div> */}
            </div>

            {/* Users and messages */}
            {repeat_message ? (
              <div>
                <div className="row">
                  <div className="col-4">
                    <label htmlFor="">Repetir cada:</label>
                    <div className="form-container d-inline-flex">
                      <CounterInput
                        min={1}
                        max={7}
                        count={repeat_periodCount}
                        onCountChange={(count) => changeRepeatSelect(count)}
                      />
                      <Select
                        options={repeatPeriodOpts}
                        value={repeat_periodDate}
                        onChange={
                          ((selectedOptions) =>
                            setRepeatPeriodDate(selectedOptions),
                          (selectedOptions) =>
                            setSelectedOption(selectedOptions))
                        }
                      ></Select>
                    </div>
                  </div>
                  <div className="col-8">
                    <label htmlFor="">Finalización:</label>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="0"
                          name="radioFinGroup"
                          onChange={(e) => HandleOptFinRadio(0)}
                          checked={finOptRadio === 0 ? true : false}
                        />
                        Nunca
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label d-inline-flex align-items-center">
                        <input
                          type="radio"
                          className="form-check-input"
                          value="1"
                          name="radioFinGroup"
                          onChange={(e) => HandleOptFinRadio(1)}
                          checked={finOptRadio === 1 ? true : false}
                        />
                        El&nbsp;
                        <Form.Control
                          className="form-control"
                          type="datetime-local"
                          name=""
                          id="finDatetimeOptID"
                          value={repetitionEndDate}
                          disabled={finDatetimeOpt}
                          min={minDateTime}
                          isInvalid={!!errors.end_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.end_date}
                        </Form.Control.Feedback>
                      </label>
                    </div>
                    {/*  
              <div className="form-check">
                <label className="form-check-label d-inline-flex align-items-center">
                  <input 
                    type="radio" 
                    className="form-check-input" 
                    value="2" 
                    name="radioFinGroup"
                    onChange={(e) => HandleOptFinRadio(2)}
                    checked={finOptRadio === 2 ? true : false}
                    />
                    Despues de&nbsp;
                    <CounterInput
                      min={1}
                      max={10}
                      count={repeat_periodEnd}
                      onCountChange={(count) => setRepeatPeriodEnd(count)}
                      btnStyle={btnFinCounterStyle}
                      inputStyle={inputFinCounterStyle}
                    /> Repeticiones
                </label>
              </div>
              */}
                  </div>
                </div>

                {showRepeatWeek ? (
                  <div className="row">
                    <div className="col">
                      <label htmlFor="">Repetir los días:&nbsp;</label>
                      <div className="weekDays-selector">{weekDays}</div>
                    </div>
                  </div>
                ) : null}

                {showRepeatMonth ? (
                  <div className="row">
                    <div className="col">
                      <label htmlFor="">Repetir el día:&nbsp;</label>
                      <div className="form-container d-inline-flex align-items-center">
                        <CounterInput
                          min={1}
                          max={31}
                          count={1}
                          onCountChange={(count) => setRepeatMonthDay(count)}
                        />
                        &nbsp;de cada mes
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="clearfix" style={{ margin: "15px" }}></div>
            <div className="row">
              <div className="col-3">
                <label htmlFor="">*Tipo de envio:</label>
                <select
                  value={type === "global" ? 0 : type === "group" ? 1 : 2}
                  className="form-select form-control"
                  onChange={(e) => selectDestinatarios(e.target.value)}
                >
                  <option value={0}>Global</option>
                  <option value={1}>Grupal</option>
                  <option value={2}>Usuarios</option>
                </select>
              </div>
              {isGlobalSend ? (
                <div className="col-4">
                  <label htmlFor=""> Destinatarios:</label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    ref={destinatariosSelectRef}
                    defaultValue={destinatarios}
                    placeholder="Selecciona aquí"
                    noOptionsMessage={(text) => 'Completo!'}
                    onChange={(selected, value) => handleDestinatarios(selected)}
                    options={fillDestinatarios}
                  />
                </div>
              ) : null}
            </div>

            <div className="clearfix" style={{ margin: "15px" }}></div>
            <div className="row">
              <div className="col-7">
                <Form.Label>*Mensaje:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  cols={30}
                  value={message}
                  onChange={(e) => setField("message", e.target.value)}
                  isInvalid={!!errors.message}
                />
                <small className="form-text text-muted">
                  Máximo 65.536 cáracteres
                </small>
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              </div>
              <div className="col-5">
                <Form.Label>Archivo multimedia:</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(value) => handleFile(value)}
                  accept=".png,.gif,.jpg,.mp4"
                />
                <small className="form-text text-muted">
                  Máximo 16mb. Formatos permitidos: .jpg, .png, .gif, .mp4
                </small>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={submitForm} type="submit">
              Crear Mensaje
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
