import moment from 'moment-timezone';
import 'moment/locale/es';

// Configurar moment para usar español
moment.locale('es');

// Zona horaria de Chile
const CHILE_TIMEZONE = 'America/Santiago';

/**
 * Formatea una fecha para mostrarla exactamente como viene de la base de datos
 * @param {string} dateString - Fecha en formato ISO o string de fecha
 * @returns {string} Fecha formateada como DD-MM-YYYY HH:mm:ss
 */
export const formatDate = (dateString) => {
  if (!dateString) return 'Sin fecha';
  
  try {
    // Extraer la fecha y hora directamente del string sin conversiones
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error('Error al formatear fecha:', error);
    return dateString;
  }
};

/**
 * Convierte una fecha local a formato UTC para enviar al servidor
 * @param {string} localDateString - Fecha local en formato ISO
 * @returns {string} Fecha en formato UTC
 */
export const localToUTC = (localDateString) => {
  if (!localDateString) return null;
  
  try {
    // Convertir de zona horaria local a UTC
    return moment.tz(localDateString, CHILE_TIMEZONE).utc().format('YYYY-MM-DD HH:mm:ss');
  } catch (error) {
    console.error('Error al convertir fecha a UTC:', error);
    return localDateString;
  }
};

/**
 * Convierte una fecha UTC a formato local
 * @param {string} utcDateString - Fecha UTC en formato ISO
 * @returns {string} Fecha en formato local
 */
export const utcToLocal = (utcDateString) => {
  if (!utcDateString) return null;
  
  try {
    // Convertir de UTC a zona horaria local
    return moment.utc(utcDateString).tz(CHILE_TIMEZONE).format('YYYY-MM-DD HH:mm:ss');
  } catch (error) {
    console.error('Error al convertir fecha a local:', error);
    return utcDateString;
  }
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function calculateAge(birthday) {
  const d = new Date(birthday);
  const ageDifMs = new Date().getFullYear() - d.getFullYear();
  
  if(isNaN(ageDifMs) || ageDifMs <= 0 || ageDifMs == null || ageDifMs == "" || ageDifMs >= 150){
    return 0;
  }
  return ageDifMs;
}
export function timeDuration(DateA, DateB) {
  const date1 = new Date(DateA);
  const date2 = new Date(DateB);

  var diff = Math.abs(date1.getTime() - date2.getTime()) / 3600000;
  return diff;
}

export function getDaysOfMonth(year, month) {
  const daysOfMonth = new Date(year, month, 0).getDate();
  const diasSemana = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  let days = [];
  for (let day = 1; day <= daysOfMonth; day++) {
    const indice = new Date(year, month - 1, day).getDay();
    days.push({
      day: day,
      day_name: diasSemana[indice],
      month: month,
      year: year,
    });
  }
  return days;
}

export function setLimitDateInput() {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
  var yyyy = today.getFullYear();

  if (dd < 10) {
      dd = '0' + dd
  }

  if (mm < 10) {
      mm = '0' + mm
  }

  today = yyyy + '-' + mm + '-' + dd;

  return today;
}

export function calculateEndRecurDate(date, repeat_cicle){

}

/**
 * Formatea una fecha directamente desde cualquier formato a DD-MM-YYYY HH:mm:ss
 * @param {string|Date} dateInput - Fecha en cualquier formato válido
 * @returns {string} Fecha formateada para visualización
 */
export const formatRawDate = (dateString) => {
  if (!dateString) return 'Sin fecha';
  
  try {
    console.log("Formateando fecha:", dateString);
    
    // Para fechas en formato ISO o cualquier otro formato
    const date = new Date(dateString);
    
    // Verificar que la fecha sea válida
    if (isNaN(date.getTime())) {
      console.error('Fecha inválida:', dateString);
      return 'Fecha inválida';
    }
    
    // Ajustar la fecha para mostrar la hora local que se ingresó originalmente
    // Si la fecha viene con Z (UTC), necesitamos ajustarla para mostrar la hora local original
    let adjustedDate = date;
    if (typeof dateString === 'string' && dateString.endsWith('Z')) {
      // Extraer la fecha y hora sin la Z
      const localDateString = dateString.slice(0, -1);
      adjustedDate = new Date(localDateString);
    }
    
    // Formatear la fecha ajustada
    const day = String(adjustedDate.getDate()).padStart(2, '0');
    const month = String(adjustedDate.getMonth() + 1).padStart(2, '0');
    const year = adjustedDate.getFullYear();
    const hours = String(adjustedDate.getHours()).padStart(2, '0');
    const minutes = String(adjustedDate.getMinutes()).padStart(2, '0');
    const seconds = String(adjustedDate.getSeconds()).padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  } catch (error) {
    console.error('Error al formatear fecha:', error);
    return dateString;
  }
};